import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './pages/logged/Dashboard';
import ApplicationLayout from './layout/ApplicationLayout';
import ProcjenaVozila from './pages/reports-creator/ProcjenaVozila';
import { useAuth } from './context/AuthContext';
import Tasks from './pages/logged/Tasks';
import Sidebar, { SidebarItem } from './layout/Sidebar';
import { LayoutDashboard } from 'lucide-react';
import Admin from './pages/logged/Admin';
import ProcjenePage from './pages/logged/ProcjenePage';
import Sektori from './pages/logged/Sektori';
import ChatsPage from './pages/logged/ChatsPage';
import Notifikacije from './pages/logged/Notifikacije';
import PrijemiNaZalihu from './pages/logged/PrijemiNaZalihu';
import AdminKorisnici from './pages/logged/AdminKorisnici';
import ProdajaDashboard from './pages/logged/ProdajaDashboard';
import PrijemiNaZalihuTockovi from './pages/logged/PrijemiNaZalihuTockovi';
import PrijemNaZalihuVozila from './pages/logged/PrijemNaZalihuVozila';
import UsersMap from './pages/logged/UsersMap';
import ZalihaArena from './pages/logged/ZalihaArena';
import EditPrijem from './pages/logged/EditPrijem';
import MenagmentAnalyses from './pages/logged/MenagmentAnalyses';
import DnevnaEvidencija from './pages/logged/DnevnaEvidencija';
import EditTask from './pages/logged/EditTask';
import VozilaUpripremi from './pages/logged/VozilaUpripremi';
import EditCar from './pages/logged/EditCar';
import EditProcjena from './pages/logged/EditProcjena';
import QRKodGenerator from './pages/logged/QRKodGenerator';

// A higher-order component to guard the routes based on authentication status
const PrivateRoute = ({ element }) => {
    const { isLoggedIn } = useAuth();

    return isLoggedIn ? element : <Navigate to="/" />;
};

function LoggedRouter() {
    return (
        <ApplicationLayout>
            <Routes>
                {/* Use PrivateRoute to guard the routes */}
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/qr-kod-generator' element={<QRKodGenerator />} />
                <Route path='/zapisnici/kreiraj-procjenu' element={<ProcjenaVozila />} />
                <Route path='/' element={<Tasks />} />
                <Route path='/admin' element={<Admin />} />
                <Route path='/procjene' element={<ProcjenePage />} />
                <Route path='/sektori' element={<Sektori />} />
                <Route path='/poruke' element={<ChatsPage />} />
                <Route path='/notifikacije' element={<Notifikacije />} />
                <Route path='/prijemi-na-zalihu' element={<PrijemiNaZalihu />} />
                <Route path='/prijemi-na-zalihu-tockovi' element={<PrijemiNaZalihuTockovi />} />
                <Route path='/admin/korisnici' element={<AdminKorisnici />} />
                <Route path='/prodaja/dashboard' element={<ProdajaDashboard />} />
                <Route path='/prijem-na-zalihu-vozila' element={<PrijemNaZalihuVozila />} />
                <Route path='/arena-zaliha' element={<ZalihaArena />} />
                <Route path='/mapa-korisnika' element={<UsersMap />} />
                <Route path='/prijem-na-zalihu-edit/:prijemId' element={<EditPrijem />} />
                <Route path='/managment-analyses' element={<MenagmentAnalyses />} />
                <Route path='/dnevna-evidencija' element={<DnevnaEvidencija />} />
                <Route path='/uredi-zadatak/:taskId' element={<EditTask />} />
                <Route path='/vozila-u-pripremi' element={<VozilaUpripremi />} />
                <Route path='/edit-vozilo/:voziloId' element={<EditCar />} />
                <Route path='/edit-procjena/:procjenaId' element={<EditProcjena />} />
            </Routes>
        </ApplicationLayout>
    );
}

// Wrap LoggedRouter with PrivateRoute
const ProtectedLoggedRouter = () => (
    <PrivateRoute element={<LoggedRouter />} />
);

export default ProtectedLoggedRouter;
