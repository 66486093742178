import { Avatar, notification, Menu, Dropdown, Button, Divider, Input, message, Tag, Modal } from 'antd'
import { ArrowRight, BarChart, BarChart4, Bell, Building2, Car, Cctv, ChevronRight, ChevronRightSquare, CircleUserRound, Info, LayoutDashboard, ListTodo, Mail, Map, MehIcon, NotebookPen, NotebookText, Paperclip, ParkingSquare, ScrollIcon, Shield, ShoppingBag, TagIcon, Users, UsersRound, XCircle } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TfiLayoutMenuFull } from "react-icons/tfi";
import { GiCartwheel, GiMechanicGarage } from "react-icons/gi";
import { useAppData } from '../context/ApplicationProvider';
import io from 'socket.io-client';
import axios from 'axios';
const socket = io("https://app.arenabackend.org", {
    reconnection: true
})


const zapisniciPages = [
    {
        title: 'Svi zapisnici',
        url: '/user/logged-in'
    },
    {
        title: 'Moji zapisnici',
        url: '/user/logged-in'
    },
    {
        title: 'Procesi vozila',
        url: '/user/logged-in'
    },
    {
        title: 'Procjene',
        url: '/user/logged-in/procjene'
    }
]

const menu = (
    <Menu>
        {
            zapisniciPages.map((zapisnik, i) => {
                return (
                    <Menu.Item key={i}>
                        <Link className='uppercase' to={zapisnik.url}>{zapisnik.title}</Link>
                    </Menu.Item>
                )
            })
        }
    </Menu>
);

function ApplicationLayout({ children }) {

    const audioSound = require('../assets/sound/notification.mp3');
    const [api, contextHolder] = notification.useNotification();
    const [activeTab, setActiveTab] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [obavjestenjeValue, setObavjestenjeValue] = useState("");
    const [mobileMenu, setMobileMenu] = useState(null)
    const { currentUser, activeWheel, pneumatici, setActiveWheel, fetchZadaci } = useAppData();

    useEffect(() => {

        socket.on('kolegaPrijavljen', (data) => {
            notification.open({
                message: 'ARENA NOTIFIKACIJA',
                description: data.message
            });
            const audio = new Audio(audioSound)
            audio.play()
            // Update your React state or perform other actions
        });

        socket.on('dodatRokZadatka', (data) => {
            fetchZadaci()
        })

        socket.on('dodaniKorisnici', (msg) => {
            notification.open({
                message: 'ARENA NOTIFIKACIJA',
                description: msg
            });
            const audio = new Audio(audioSound)
            audio.play()
        });

        socket.on('prihvacenaProcjena', (msg) => {
            notification.open({
                message: 'ARENA NOTIFIKACIJA',
                description: msg
            });
            const audio = new Audio(audioSound)
            audio.play()
        });

        socket.on('odbijenaProcjena', (msg) => {
            notification.open({
                message: 'ARENA NOTIFIKACIJA',
                description: msg
            });
            const audio = new Audio(audioSound)
            audio.play()
        });


        socket.on('novaProcjena', (msg) => {
            api.info({
                message: `Notifikacija`,
                description: msg,
                placement: 'topRight',
            });
            const audio = new Audio(audioSound)
            audio.play()
        });

        socket.on('finishedTask', (msg) => {
            api.info({
                message: `Notifikacija`,
                description: msg,
                placement: 'topRight',
            });
            const audio = new Audio(audioSound)
            audio.play()
        });

        socket.on('kreiranNoviZapisnik', (msg) => {
            api.info({
                message: `Notifikacija`,
                description: msg,
                placement: 'topRight',
            });
            const audio = new Audio(audioSound)
            audio.play()
        });

        socket.on('finishedTask', (msg) => {
            fetchZadaci()
        });

        socket.on('kreiranZadatak', (msg) => {
            const isMe = currentUser._id === msg

            if (isMe === true) {
                api.info({
                    message: `Notifikacija`,
                    description: `Dobili ste novi zadatak`,
                    placement: 'topRight',
                });
                const audio = new Audio(audioSound)
                audio.play()
            } else {
                return null
            }
        });


        // Clean up the socket connection when the component unmounts
        return () => {
            socket.disconnect();
        };
    }, []);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const submit = async () => {
        try {
            const response = await axios.post('https://app.arenabackend.org/administration/post-obavjestenje', {
                title: obavjestenjeValue
            });

            if (!response) {
                alert('Greska prilikom kreiranja obavjestenja')
            }

            socket.emit('novoObavjestenje', obavjestenjeValue);
        } catch (error) {
            console.error('Error:', error);
            alert("Greška prilikom slanja zahtjeva");
        }
    };


    return (
        <div className='flex'>
            {contextHolder}
            <Modal title="Kreiraj obavještenje" open={isModalOpen} onOk={submit} onCancel={handleCancel} cancelText='Otkazi' okText='Potvrdi' okType='default' >
                <Input onChange={(e) => setObavjestenjeValue(e.target.value)} defaultValue={obavjestenjeValue} placeholder='Unesite obavještenje' className='p-2 mt-3' />
            </Modal>
            <div style={{ backgroundColor: '#C93030', color: 'white', minWidth: 300, left: mobileMenu ? 0 : null, height: '100vh', overflowY: 'auto' }} className='flex flex-col items-start py-2 arena-sidebar relative'>
                <a href='/user/logged-in' className='flex items-center gap-3 px-2 pb-10'>
                    <img src="https://res.cloudinary.com/dxo3z5off/image/upload/f_auto,q_auto/v1/topc/xjtz6alde1eibswovm7z" style={{ width: 60, height: 60 }} className='rounded-full' alt="" />
                    <h1 style={{ fontSize: 15 }} className='text-white'>ARENA <strong>MOTORS</strong></h1>
                </a>
                <div className='px-2 pb-2 w-full'>
                    <Link to="/user/logged-in/qr-kod-generator" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full relative'>QR Kod Generator <span className='text-xs absolute top-2 right-2'>NOVO</span></Link>
                </div>
                {
                    currentUser.role === "administrator" ? (
                        <div className='flex flex-col items-start gap-3 w-full px-2'>
                            <Link onClick={() => setActiveTab(0)} style={{ backgroundColor: activeTab === 0 ? '#db3d3d' : null }} to="/user/logged-in/" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><ListTodo size={20} />Zadaci</Link>
                            <Link onClick={() => setActiveTab(1)} style={{ backgroundColor: activeTab === 1 ? '#db3d3d' : null }} to="/user/logged-in/prijemi-na-zalihu" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><ScrollIcon size={20} />Prijemi na zalihu</Link>
                            <Link onClick={() => setActiveTab(2)} style={{ backgroundColor: activeTab === 2 ? '#db3d3d' : null }} to="/user/logged-in/prijem-na-zalihu-vozila" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><TagIcon size={20} />Prodata vozila</Link>
                            <Link onClick={() => setActiveTab(3)} style={{ backgroundColor: activeTab === 3 ? '#db3d3d' : null }} to="/user/logged-in/prijemi-na-zalihu-tockovi" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><GiCartwheel size={20} />Prijemi na zalihu točkova</Link>
                            <Link onClick={() => setActiveTab(4)} style={{ backgroundColor: activeTab === 4 ? '#db3d3d' : null }} to="/user/logged-in/procjene" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><NotebookPen size={20} />Procjene</Link>
                            <Link onClick={() => setActiveTab(5)} style={{ backgroundColor: activeTab === 5 ? '#db3d3d' : null }} to="/user/logged-in/arena-zaliha" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><ParkingSquare size={20} />Zaliha</Link>
                            <Link onClick={() => setActiveTab(6)} style={{ backgroundColor: activeTab === 6 ? '#db3d3d' : null }} to="/user/logged-in/vozila-u-pripremi" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><GiMechanicGarage size={20} />Vozila u Pripremi</Link>
                            <div style={{ height: 1 }} className='bg-gray-50 opacity-25 w-full'></div>
                            <Link onClick={() => setActiveTab(7)} style={{ backgroundColor: activeTab === 7 ? '#db3d3d' : null }} to="/user/logged-in/prodaja/dashboard" className='text-sm hover:bg-blue-400 p-3 rounded flex items-center gap-2 w-full'><Info size={20} stroke="white" />Prodaja</Link>
                            <div style={{ height: 1 }} className='bg-gray-50 opacity-25 w-full'></div>
                            {
                                currentUser.email === "david@arenamotors.ba" || currentUser.email === "info@arenamotors.ba" || currentUser.email === "nemanjaad@hotmail.com" ? (
                                    <Link onClick={() => setActiveTab(8)} style={{ backgroundColor: activeTab === 8 ? '#db3d3d' : null }} to="/user/logged-in/dnevna-evidencija" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Paperclip size={20} />Dnevna Evidencija</Link>
                                ) : null
                            }
                            <Link onClick={() => setActiveTab(9)} style={{ backgroundColor: activeTab === 9 ? '#db3d3d' : null }} to="/user/logged-in/admin" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Shield size={20} />Admin</Link>
                            <Link onClick={() => setActiveTab(10)} style={{ backgroundColor: activeTab === 10 ? '#db3d3d' : null }} to="/user/logged-in/managment-analyses" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><BarChart4 size={20} />Menadzment Analitike</Link>
                            <Link onClick={() => setActiveTab(11)} style={{ backgroundColor: activeTab === 11 ? '#db3d3d' : null }} to="/user/logged-in/mapa-korisnika" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Map size={20} />Mapa korisnika</Link>
                            <Link onClick={() => setActiveTab(12)} style={{ backgroundColor: activeTab === 12 ? '#db3d3d' : null }} to="/user/logged-in/admin/korisnici" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><UsersRound size={20} />Korisnici</Link>
                            <Link onClick={() => setActiveTab(13)} style={{ backgroundColor: activeTab === 13 ? '#db3d3d' : null }} to="/user/logged-in/sektori" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Building2 size={20} />Sektori</Link>
                            <Link onClick={() => setActiveTab(14)} style={{ backgroundColor: activeTab === 14 ? '#db3d3d' : null }} to="/user/logged-in/" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Cctv size={20} />Kamere</Link>
                            <Link onClick={() => setActiveTab(15)} style={{ backgroundColor: activeTab === 15 ? '#db3d3d' : null }} to="/user/logged-in/" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><ShoppingBag size={20} />Poruceni djelovi</Link>
                            <Link onClick={() => setActiveTab(16)} style={{ backgroundColor: activeTab === 16 ? '#db3d3d' : null }} to="/user/logged-in/" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Car size={20} />Vozila</Link>
                            <div style={{ height: 1 }} className='bg-gray-50 opacity-25 w-full'></div>
                            <button onClick={() => setIsModalOpen(true)} className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'>Kreiraj obavještenje</button>
                            <Link onClick={() => setActiveTab(17)} style={{ backgroundColor: activeTab === 17 ? '#db3d3d' : null }} to="/user/logged-in/" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-4 w-full justify-center bg-red-500'>Odjavi se</Link>
                        </div>
                    ) : currentUser.role === "prodaja" ? (
                        <div className='flex flex-col items-start gap-3 w-full px-2'>
                            <Link to="/user/logged-in/" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><ListTodo size={20} />Zadaci</Link>
                            <Link to="/user/logged-in/prijemi-na-zalihu" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><Shield size={20} />Prijemi na zalihu</Link>
                            <Link to="/user/logged-in/prijemi-na-zalihu-tockovi" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><Shield size={20} />Prijemi na zalihu točkova</Link>
                            <Link to="/user/logged-in/procjene" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><Shield size={20} />Procjene</Link>
                            <div style={{ height: 1 }} className='bg-gray-50 opacity-25 w-full'></div>
                            <Link to="/user/logged-in/" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-4 w-full justify-center bg-red-500'>Odjavi se</Link>
                        </div>
                    ) : currentUser.role === "priprema" ? (
                        <div className='flex flex-col items-start gap-3 w-full px-2'>
                            <Link to="/user/logged-in/" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><ListTodo size={20} />Zadaci</Link>
                            <Link to="/user/logged-in/prijemi-na-zalihu" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><Shield size={20} />Prijemi na zalihu</Link>
                            <Link to="/user/logged-in/prijemi-na-zalihu-tockovi" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><Shield size={20} />Prijemi na zalihu točkova</Link>
                            <Link to="/user/logged-in/procjene" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><Shield size={20} />Procjene</Link>
                            <div style={{ height: 1 }} className='bg-gray-50 opacity-25 w-full'></div>
                            <Link to="/user/logged-in/" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-4 w-full justify-center bg-red-500'>Odjavi se</Link>
                        </div>
                    ) : currentUser.role === "custom" ? (
                        <div className='flex flex-col items-start gap-3 w-full px-2'>
                            <Link onClick={() => setActiveTab(0)} style={{ backgroundColor: activeTab === 0 ? '#db3d3d' : null }} to="/user/logged-in/" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><ListTodo size={20} />Zadaci</Link>
                            <Link onClick={() => setActiveTab(1)} style={{ backgroundColor: activeTab === 1 ? '#db3d3d' : null }} to="/user/logged-in/prijemi-na-zalihu" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><ScrollIcon size={20} />Prijemi na zalihu</Link>
                            <Link onClick={() => setActiveTab(3)} style={{ backgroundColor: activeTab === 3 ? '#db3d3d' : null }} to="/user/logged-in/prijemi-na-zalihu-tockovi" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><GiCartwheel size={20} />Prijemi na zalihu točkova</Link>
                            <Link onClick={() => setActiveTab(4)} style={{ backgroundColor: activeTab === 4 ? '#db3d3d' : null }} to="/user/logged-in/procjene" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><NotebookPen size={20} />Procjene</Link>
                            <Link onClick={() => setActiveTab(5)} style={{ backgroundColor: activeTab === 5 ? '#db3d3d' : null }} to="/user/logged-in/arena-zaliha" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><ParkingSquare size={20} />Zaliha</Link>
                            <Link onClick={() => setActiveTab(6)} style={{ backgroundColor: activeTab === 6 ? '#db3d3d' : null }} to="/user/logged-in/vozila-u-pripremi" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><GiMechanicGarage size={20} />Vozila u Pripremi</Link>
                            <div style={{ height: 1 }} className='bg-gray-50 opacity-25 w-full'></div>
                            <Link onClick={() => setActiveTab(7)} style={{ backgroundColor: activeTab === 7 ? '#db3d3d' : null }} to="/user/logged-in/prodaja/dashboard" className='text-sm hover:bg-blue-400 p-3 rounded flex items-center gap-2 w-full'><Info size={20} stroke="white" />Prodaja</Link>
                            <div style={{ height: 1 }} className='bg-gray-50 opacity-25 w-full'></div>
                            {
                                currentUser.email === "david@arenamotors.ba" || currentUser.email === "info@arenamotors.ba" || currentUser.email === "nemanjaad@hotmail.com" ? (
                                    <Link onClick={() => setActiveTab(8)} style={{ backgroundColor: activeTab === 8 ? '#db3d3d' : null }} to="/user/logged-in/dnevna-evidencija" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Paperclip size={20} />Dnevna Evidencija</Link>
                                ) : null
                            }
                            <Link onClick={() => setActiveTab(9)} style={{ backgroundColor: activeTab === 9 ? '#db3d3d' : null }} to="/user/logged-in/admin" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Shield size={20} />Admin</Link>
                            <Link onClick={() => setActiveTab(10)} style={{ backgroundColor: activeTab === 10 ? '#db3d3d' : null }} to="/user/logged-in/managment-analyses" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><BarChart4 size={20} />Menadzment Analitike</Link>
                            <Link onClick={() => setActiveTab(11)} style={{ backgroundColor: activeTab === 11 ? '#db3d3d' : null }} to="/user/logged-in/mapa-korisnika" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Map size={20} />Mapa korisnika</Link>
                            <Link onClick={() => setActiveTab(12)} style={{ backgroundColor: activeTab === 12 ? '#db3d3d' : null }} to="/user/logged-in/admin/korisnici" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><UsersRound size={20} />Korisnici</Link>
                            <Link onClick={() => setActiveTab(13)} style={{ backgroundColor: activeTab === 13 ? '#db3d3d' : null }} to="/user/logged-in/sektori" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Building2 size={20} />Sektori</Link>
                            <Link onClick={() => setActiveTab(14)} style={{ backgroundColor: activeTab === 14 ? '#db3d3d' : null }} to="/user/logged-in/" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Cctv size={20} />Kamere</Link>
                            <Link onClick={() => setActiveTab(15)} style={{ backgroundColor: activeTab === 15 ? '#db3d3d' : null }} to="/user/logged-in/" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><ShoppingBag size={20} />Poruceni djelovi</Link>
                            <Link onClick={() => setActiveTab(16)} style={{ backgroundColor: activeTab === 16 ? '#db3d3d' : null }} to="/user/logged-in/" className='text-sm hover:bg-green-400 p-3 rounded flex items-center gap-2 w-full'><Car size={20} />Vozila</Link>
                            <div style={{ height: 1 }} className='bg-gray-50 opacity-25 w-full'></div>
                            <button onClick={() => setIsModalOpen(true)} className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'>Kreiraj obavještenje</button>
                            <Link onClick={() => setActiveTab(17)} style={{ backgroundColor: activeTab === 17 ? '#db3d3d' : null }} to="/user/logged-in/" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-4 w-full justify-center bg-red-500'>Odjavi se</Link>
                        </div>
                    ) : (
                        <div className='flex flex-col items-start gap-3 w-full px-2'>
                            <Link to="/user/logged-in/" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><ListTodo size={20} />Zadaci</Link>
                            <Link to="/user/logged-in/prijemi-na-zalihu" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><Shield size={20} />Prijemi na zalihu</Link>
                            <Link to="/user/logged-in/prijemi-na-zalihu-tockovi" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><Shield size={20} />Prijemi na zalihu točkova</Link>
                            <Link to="/user/logged-in/procjene" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-2 w-full'><Shield size={20} />Procjene</Link>
                            <div style={{ height: 1 }} className='bg-gray-50 opacity-25 w-full'></div>
                            <Link to="/user/logged-in/" className='text-sm hover:bg-red-400 p-3 rounded flex items-center gap-4 w-full justify-center bg-red-500'>Odjavi se</Link>
                        </div>
                    )
                }

                <button onClick={() => setMobileMenu(!mobileMenu)} style={{ height: 70, width: 20, right: -20, backgroundColor: '#C93030' }} className='menu-button-mobile rounded-s-lg flex items-center justify-center' dir='rtl'>
                    <ChevronRight size={18} style={{ rotate: mobileMenu ? '180deg' : null }} />
                </button>
            </div>
            <section className='page-container' style={{ height: '100vh', overflow: 'auto', width: 'calc(100vw - 300px)' }}>
                {/* <div className="input p-3">
                    <Input placeholder='Pretražite sistem' className='p-3 rounded-full' />
                </div> */}
                {children}
            </section>
            {
                activeWheel ? (
                    <div className='absolute top-0 left-0 flex items-center justify-center' onClick={() => setActiveWheel(null)} style={{ width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 999999 }}>
                        {pneumatici.filter((pneumatik) => pneumatik._id === activeWheel).map((pneumatik) => {
                            return (
                                <div onClick={(e) => e.stopPropagation()} className='bg-white p-3 w-2/3 overflow-y-auto'>
                                    <div className='pb-4'>
                                        <h1 className='font-semibold'># {pneumatik._id}</h1>
                                    </div>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.lotBroj ? null : '#d22829', backgroundColor: pneumatik.lotBroj ? null : 'rgba(210, 40, 40, 0.1)' }}>Lot broj: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.lotBroj || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.tip ? null : '#d22829', backgroundColor: pneumatik.tip ? null : 'rgba(210, 40, 40, 0.1)', }}>Tip: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.tip || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.brojKomada ? null : '#d22829', backgroundColor: pneumatik.brojKomada ? null : 'rgba(210, 40, 40, 0.1)', }}>Broj komada: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.brojKomada || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.brend ? null : '#d22829', backgroundColor: pneumatik.brend ? null : 'rgba(210, 40, 40, 0.1)', }}>Brend: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.brend || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.vozilo ? null : '#d22829', backgroundColor: pneumatik.vozilo ? null : 'rgba(210, 40, 40, 0.1)', }}>Vozilo: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.vozilo || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.velicina ? null : '#d22829', backgroundColor: pneumatik.velicina ? null : 'rgba(210, 40, 40, 0.1)', }}>Veličina: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.velicina || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.etJotFelgi ? null : '#d22829', backgroundColor: pneumatik.etJotFelgi ? null : 'rgba(210, 40, 40, 0.1)', }}>ET / JOT Felgi: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.etJotFelgi || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.markaTipGuma ? null : '#d22829', backgroundColor: pneumatik.markaTipGuma ? null : 'rgba(210, 40, 40, 0.1)', }}>Marka, tip guma: (ljetne, zimske, allseason): <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.markaTipGuma || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.sezonaGuma ? null : '#d22829', backgroundColor: pneumatik.sezonaGuma ? null : 'rgba(210, 40, 40, 0.1)', }}>Ljetne / Zimske / Allseason: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.sezonaGuma || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.dimenzija ? null : '#d22829', backgroundColor: pneumatik.dimenzija ? null : 'rgba(210, 40, 40, 0.1)', }}>Dimezije: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.dimenzija || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.dot ? null : '#d22829', backgroundColor: pneumatik.dot ? null : 'rgba(210, 40, 40, 0.1)', }}>DOT: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.dot || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.dubinaSare ? null : '#d22829', backgroundColor: pneumatik.dubinaSare ? null : 'rgba(210, 40, 40, 0.1)', }}>Dubina šare: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.dubinaSare || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.ekseri ? null : '#d22829', backgroundColor: pneumatik.ekseri ? null : 'rgba(210, 40, 40, 0.1)', }}>Ekseri ( DA , NE , BILI ): <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.ekseri || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.skladiste ? null : '#d22829', backgroundColor: pneumatik.skladiste ? null : 'rgba(210, 40, 40, 0.1)', }}>Skladišteno: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.skladiste || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between border-b p-2' style={{ borderColor: pneumatik.napomena ? null : '#d22829', backgroundColor: pneumatik.napomena ? null : 'rgba(210, 40, 40, 0.1)', }}>Napomena: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.napomena || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                    <h1 className='flex items-center justify-between p-2 border-b' style={{ borderColor: pneumatik.dostupnost ? null : '#d22829', backgroundColor: pneumatik.dostupnost ? null : 'rgba(210, 40, 40, 0.1)', }}>Dostupnost: <span className='font-semibold border-l border-l-gray-300 pl-3 w-2/3'>{pneumatik.dostupnost || <span className='font-normal' style={{ fontSize: 12 }}>prazno polje</span>}</span></h1>
                                </div>
                            )
                        })}
                    </div>
                ) : null
            }
        </div>
    )
}

export default ApplicationLayout