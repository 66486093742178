import React, { useState } from 'react'
import QRWithLogo from '../../componets/QRCodeComponent'

function QRKodGenerator() {

  const [qrValue, setQRValue] = useState("");


  return (
    <div className='w-full h-full flex flex-col gap-6 items-center justify-center'>
      <h1 className='text-2xl font-bold text-center'>GENERIŠI SVOJ QR KOD</h1>
      <input className='text-xs bg-gray-50 border p-4' style={{ width: 250 }} type="text" placeholder="Unesite url za vaš qr kod" onChange={(e) => setQRValue(e.target.value)} />
      <QRWithLogo value={qrValue} />
    </div>
  )
}

export default QRKodGenerator