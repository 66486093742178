import React, { useRef } from 'react';
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';
import logo from '../assets/images/apploog.png'

const QRWithLogo = ({ value }) => {
    const qrRef = useRef();

    const downloadQR = async () => {
        if (qrRef.current === null) {
            return;
        }

        try {
            const dataUrl = await toPng(qrRef.current);
            const link = document.createElement('a');
            link.download = 'qr-code.png';
            link.href = dataUrl;
            link.click();
        } catch (error) {
            console.error('Error generating QR Code:', error);
        }
    };

    return (
        <div className='flex flex-col gap-3'>
            <div ref={qrRef} style={{ position: 'relative', display: 'inline-block' }}>
                <QRCode value={value} size={256} />
                <img
                    src={logo}
                    alt="Logo"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50px', // Podešavanje veličine logotipa
                        height: '50px',
                    }}
                />
            </div>
            <button className='bg-red-500 text-white p-4' onClick={downloadQR} style={{ marginTop: '10px' }}>
                Preuzmi QR KOD
            </button>
        </div>
    );
};

export default QRWithLogo;
